.services {
  text-align: center;
  padding-bottom: 26.7rem;
  h2 {
    padding: 14.4rem 0 4.8rem 0;
  }
  h3 {
    padding: 1rem 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    justify-content: center;
  }
  &-item {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
    padding: 6.4rem 1.6rem;
    border-radius: 4rem;
    border: 1px solid var(--Gray, #232d3f);
    background: radial-gradient(
      123.04% 180.42% at 49.9% -65.25%,
      rgba(15, 15, 15, 0) 61.82%,
      #005ec9 89.77%,
      #f7f7f8 100%
    );
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      justify-content: center;
      align-items: center;
    }
    button {
      border-radius: 3.2rem;
      background: rgba(247, 247, 248, 0.1);
      padding: 0.8rem 1.6rem;
      color: var(--white);
      font-size: 2.1rem;
    }

    &-transparent {
      background: transparent;
    }
  }
}

@media (max-width: 768px) {
  .services {
    padding-bottom: 5.6rem;
  }
}