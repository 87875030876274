/* reset styles*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-size: inherit;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  line-height: 1.26;  */
}

a {
  text-decoration: none; /* Removes underlines */
  color: inherit; /* Inherits the text color from the parent */
}
/* end reset styles */

/* main styles */
* {
  /* font-family: 'Inter', sans-serif; */
}

html {
  background: var(--black);
  color: var(--white);
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

// img {
//   width: 100%;
// }

p {
  color: var(--gray);
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 142.857%;
}

h1 {
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 125%;
}

h2 {
  font-size: 5.1rem;
  font-weight: 700;
}

h3 {
  font-size: 4.1rem;
  font-weight: 700;
}

h4 {
  font-size: 3.3rem;
  font-weight: 700;
}

h5 {
  font-size: 2.8rem;
  font-weight: 700;
}

h6 {
  font-size: 2.1rem;
  font-weight: 700;
}

@media (max-width: 1366px) {
  p {
    color: var(--gray);
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 138.889%;
  }
  h1 {
    font-size: 5.5rem;
  }
  h2 {
    font-size: 4.4rem;
  }
  h3 {
    font-size: 3.5rem;
  }
  h4 {
    font-size: 2.8rem;
  }
  h5 {
    font-size: 2.3rem;
  }
  h6 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  p {
    color: var(--gray);
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
  }
  h1 {
    font-size: 3.2rem;
    line-height: normal;
  }
  h2 {
    font-size: 2.8rem;
    line-height: 125%;
  }
  h3 {
    font-size: 2.6rem;
  }
  h4 {
    font-size: 2.3rem;
  }
  h5 {
    font-size: 2rem;
  }
  h6 {
    font-size: 1.8rem;
  }
}


/* variabile */

:root {
  --primary: #005ec9;
  --secondary: #0b3c74;
  --black: #0f0f0d;
  --white: #f7f7f8;
  --bluegray: #232d3f;
  --gray: #aaaeb5;
}

/* HELPERS */

.text-black {
  color: var(--black);
}
.text-white {
  color: var(--white);
}
.text-gray {
  color: var(--gray);
}
.bg-black {
  background-color: var(--black);
}
.bg-white {
  background-color: var(--white);
}
.bg-transparent {
  background-color: transparent;
}
