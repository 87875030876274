.language {
  position: relative;
  font-size: 2.1rem;
  padding: 1.6rem 0rem 1.6rem 0.4rem;
  
  &--mobile {
    display: none;
  }
  &-selected {
    display: flex;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    img {
      margin-top: 0.4rem;
      padding-left: 0.4rem;
    }
  }
  &-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: -1.3rem;
    bottom: -7.2rem;
    padding: 0.8rem 1.6rem;
    border-radius: 16px;
    border: 1px solid var(--Gray, #232d3f);
    z-index: 50;
  }
  &-item {
    padding: 0.4rem 0;
    button {
      font-size: 2.1rem;
      background: transparent;
      color: var(--white);
    }
    &--active {
      display: none;
    }
  }
}
@media (max-width: 1024px) {
  .language {
    display: none;
    &--mobile {
      display: block;
    }
    &-list {
      left: 5.6rem;
      bottom: -1.6rem;
    }
  }
}
