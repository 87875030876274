#portfolioProjects {
  margin-top: 6rem;
  padding-top: 6rem;
}

.portfolioProjects {
  &-list {
    display: flex;
    flex-direction: column;
  }
  &-item {
    display: flex;
    align-items: center;
    min-height: 75.6rem;
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
    gap: 4.8rem;
    border-radius: 40px;
    border: 2px solid var(--bluegray);
    margin-bottom: 12.8rem;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      max-height: 100%;
      border-radius: 1.2rem;
      overflow: hidden;
      object-fit: contain;
    }

    h2 {
      padding-right: 3.2rem;
      margin-right: 3.2rem;
      border-right: 2px solid var(--gray);
    }
    span {
      font-size: 2.8rem;
    }
    h4 {
      color: var(--gray);
    }

    &-top {
      display: flex;
      align-items: center;
    }
    &-texts {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    .column-text {
    }
    .column-image {
      align-items: start;
      height: auto;
    }
  }
}

@media (max-width: 1850px) {
  .portfolioProjects {
    &-item {
      h2 {
        border-right: none;
      }
      span {
        display: inline-block;
        margin-top: 0.4rem;
        margin-bottom: 0.8rem;
      }

      &-top {
        flex-direction: column;
        align-items: start;
      }
    }
  }
}

@media (max-width: 1024px) {
  .portfolioProjects {
    &-list {
    }
    &-item {
      margin-left: 3.2rem;
      margin-right: 3.2rem;
      margin-bottom: 6.4rem;
      padding: 4.8rem 3.2rem;
      min-height: 0;
      flex-wrap: wrap;

      h2 {
        border-right: 2px solid var(--gray);
      }
      &-top {
        flex-direction: row;
      }

      .column {
        width: 100%;
      }
      .column-image {
        display: block !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .portfolioProjects {
    &-item {
      h2 {
        border-right: none;
      }
      span {
        display: inline-block;
        font-size: 1.8rem;
        margin-top: 0.4rem;
        margin-bottom: 0.8rem;
      }

      &-top {
        flex-direction: column;
        align-items: start;
      }
      &-texts {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
      }
    }
  }
}

@media (max-width: 375px) {
  .portfolioProjects {
    &-item {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 6.4rem;
    }
  }
}
