.generalInfo {
    text-align: center;
    padding: 9.6rem 1.6rem;
  
    &-column {
      width: 50%;
      display: flex;
      &:first-child {
        flex-direction: column;
        gap: 1.6rem;
      }
      &:last-child {
        justify-content: center;
      }
    }
  }