#contactUs,
#contactUs-content {
  margin-top: -6rem;
  padding-top: 6rem;
}

.contactUs {
  text-align: center;
  padding-bottom: 25.6rem;
  h2 {
    padding: 25.6rem 19.2rem 4.8rem 19.2rem;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    padding: 6.4rem 8rem;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    flex: 84rem 1 1;
    border-radius: 4rem;
    border: 1px solid var(--bluegray);
    background: radial-gradient(
      150.43% 118.4% at 52.26% -10.11%,
      rgba(15, 15, 15, 0) 58.82%,
      #005ec9 88.77%,
      #f7f7f8 100%
    ) !important;

    &-row {
      text-align: start;
      width: 100%;
    }

    label {
      font-size: 1.7rem;
    }

    input,
    textarea {
      margin-top: 0.8rem;
      color: var(--white);
      width: 100%;
      padding: 0.4rem 1.6rem;
      font-size: 2.1rem;
      background: rgba(35, 45, 63, 0.35);
      resize: none;
      border-radius: 0.6rem;
    }
    input:focus,
    textarea:focus {
      border: 1px solid var(--white);
      margin: -1px;
      margin-top: 0.7rem;
    }

    button {
      padding: 0.8rem 3.2rem;
      border-radius: 3.2rem;
      background: rgba(247, 247, 248, 0.1);
      font-size: 2.1rem;
      color: var(--white);
    }
  }

  &-infoBlock {
    h3 {
      margin-bottom: 1.6rem;
    }
    display: flex;
    flex: 54.4rem 1 1;
    flex-direction: column;
    padding: 6.4rem 2.4rem;
    align-items: center;
    justify-content: center;
    border-radius: 4rem;
    border: 1px solid var(--bluegray);

    a {
      color: var(--gray);
      font-size: 2.1rem;
      font-weight: 400;
      line-height: 1.6;
    }
  }
}

@media (max-width: 1440px) {
  .contactUs {
    padding-bottom: 18.4rem;

    &-form {
      padding: 4.8rem 2.4rem;
    }
    &-infoBlock {
      padding: 4.8rem 2.4rem;
    }

    h2 {
      padding: 18.4rem 2.4rem 2.4rem;
    }
  }
}

@media (max-width: 425px) {
  .contactUs.padding {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
