.price {
  text-align: center;
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    border-radius: 4rem;
    border: 1px solid var(--Gray, #232d3f);
    background: rgba(0, 94, 201, 0.05);
    padding: 9.6rem 0px;
    justify-content: center;
    align-items: center;

    h3 {
      color: var(--gray);
    }
  }

  &-header {
    display: block;
    &Mobile {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .price {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    &-content {
      padding: 6.4rem 2.4rem;
    }

    &-header {
      display: none;
      &Mobile {
        display: block;
      }
    }
  }
}
