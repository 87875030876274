.hero {
  &-content {
    background: radial-gradient(
      102% 97.13% at 50% 10%,
      rgba(15, 15, 15, 0) 50%,
      #005ec9 87%,
      #f7f7f8 100%
    )  !important;
  }

  &-buttons {
    button {
      font-size: 2.1rem;
      padding: 0.4rem 1.6rem;
      border-radius: 3.2rem;
      font-weight: 700;
    }
  }
}
