.logo {
  height: 4rem;
  img {
    height: 100%;
    &.logoImage {
      display: block;
    }
    &.mobileLogoImage {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .logo {
    height: 3.2rem;
    img {
      &.logoImage {
        display: none;
      }
      &.mobileLogoImage {
        display: block;
      }
    }
  }
}
