#offers {
  margin-top: -6rem;
  padding-top: 6rem;
}

.offers {
  text-align: center;
  padding: 25.6rem 0;

  &-content {
    gap: 3.2rem;
    display: flex;
    flex-direction: column;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 50%;
    border-right: 1px solid var(--bluegray);
    border-bottom: 1px solid var(--bluegray);
    padding: 6.4rem;

    &:nth-child(2n) {
      border-right: none;
    }
    &:nth-last-child(-n+2)  {
      border-bottom: none;
    }
  }
}
@media (max-width: 1366px) {
  .offers {
    &-content {
      padding-left: 12rem;
      padding-right: 12rem;
    }
  }
}
@media (max-width: 1024px) {
  .offers {
    &-content {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
  }
}

@media (max-width: 768px) {
  .offers {
    padding: 15.2rem 0;
    &-item {
      width: 100%;
      border: 0;
      padding: 2.4rem 0;
    }
  }
}
