.topV2 {
  &-content {
    min-height: 91.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // flex-wrap: wrap;
    gap: 2.4rem;
    img {
      height: 5rem;
    }
  }
}

@media (max-width: 1366px) {
  .topV2 {
    &-headerContainer {
      img {
        height: 4.3rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .topV2 {
    &-content {
      min-height: auto;
      & > img {
        display: none;
      }
    }
    &-headerContainer {
      flex-direction: column;
      gap: 0.8rem;
      h1 {
        font-size: 4rem;
      }
      img {
        height: 3.1rem;
      }
    }
  }
}
@media (max-width: 425px) {
  .topV2 {
    &-headerContainer {
      h1 {
        font-size: 3.2rem;
      }
      img {
        height: 2.5rem;
      }
    }
  }
}
