.navbar {
  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 3.2rem;
  }
  &-item {
    margin-left: 2.4rem;

    &--mobile {
      display: none;
    }

    .transparentButton {
      padding: 0;
      color: var(--white);
      font-weight: 400;
    }
  }

  &-menu,
  &-menu--active {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-logo {
      display: none;
    }
  }

  &-menuMobile,
  &-menuMobile-close {
    display: none;
    font-size: 3.2rem;
    padding: 0.4rem 2.4rem;
    border-radius: 32px;
    background: rgba(247, 247, 248, 0.1);
    font-weight: 600;
    justify-content: center;
    align-items: center;
    height: 4rem;
  }

  &-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
  }
}

@media (max-width: 1024px) {
  .navbar {
    &-menu {
      display: none;
      &-logo {
        display: block;
      }
      

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 6.4rem;
      }
      &-footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: end;
        padding-bottom: 8rem;
      }
      &-logo {
        position: relative;
        height: 3.2rem;
        bottom: -0.4rem;
        left: -0.8rem;
      }

      &--active {
        display: flex;
        position: absolute;
        flex-direction: column;
        padding: 4rem 4rem;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: var(--black);
        z-index: 101;
      }
    }

    &-item {
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      &--mobile {
        display: flex;
        button {
          border-bottom: 2px solid var(--white) !important;
        }
      }

      & > * {
        width: 100%;
      }

      button {
        color: var(--white);
        background: transparent;
        padding: 3.2rem 0 0.8rem !important;
        width: 100%;
        border-radius: 1.6rem;
        border-bottom: 2px solid var(--bluegray);
        font-weight: 400;
      }
    }

    &-menuMobile,
    &-menuMobile-close {
      display: flex;

      &-close {
        display: flex;
        align-self: end;
        margin-bottom: 1.6rem;
        margin-right: -0.8rem;
      }
    }
  }
  .logo {
    height: 3.2rem;
    img {
      &.Logo {
        display: none;
      }
      &.mobileLogo {
        display: block;
      }
    }
  }
}
