.allServices {
  margin-top: 12.8rem;
  margin-bottom: 25.6rem;
  gap: 6.4rem;

  &-service {
    display: flex;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    .column-image {
      display: block !important;
    }
    img {
    }
  }
}

@media (max-width: 768px) {
  .allServices {
    margin-bottom: 18.4rem;
    &-service {
      flex-wrap: wrap;
    }
    .reverseColumnMobile {
      flex-direction: column-reverse;
    }
  }
}
