// general :::
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

* {
  font-family: "Inter", sans-serif;
  text-underline-offset: 0.6rem;
}
body {
  overflow: auto;
}
.container {
  max-width: 179.2rem;
  margin: auto;
}
.padding {
  padding-left: 19.2rem;
  padding-right: 19.2rem;
}
.marginBottom {
  margin-bottom: 25.6rem;
}
.block {
  display: flex;
  align-items: center;
  min-height: 100rem;
  border-radius: 4rem;
  gap: 2.4rem;
  background: radial-gradient(
    102% 97.13% at 50% 10%,
    rgba(15, 15, 15, 0) 50%,
    #005ec9 87%,
    #f7f7f8 100%
  );
  &-bulb {
    background: radial-gradient(
      133.26% 146.75% at 50% 0%,
      #f7f7f8 0%,
      #005ec9 29.69%,
      rgba(15, 15, 15, 0) 68.75%,
      rgba(15, 15, 15, 0) 100%
    ) !important;
  }
}
.column {
  width: calc(50% - 1.2rem);
  display: flex;
}
.column-text {
  flex-direction: column;
  gap: 1.6rem;
}
.column-image {
  justify-content: center;
  img {
    max-width: 100%;
  }
}

.linkDecoration {
  display: inline-block;
  color: var(--gray);
  font-weight: 700;
  padding-bottom: 2px;
  border-bottom: 3px solid var(--gray);
  margin-bottom: -4px;
  transition: border-bottom 0.3s ease;

  &:hover {
    color: var(--white);
    border-bottom-color: #2f89df;
  }
}

.reverseColumn1024,
.reverseColumn768 {
  flex-direction: row;
}

@media (max-width: 1366px) {
  .padding {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .marginBottom {
    margin-bottom: 19.2rem;
  }
}

@media (max-width: 1024px) {
  .reverseColumn1024 {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .marginBottom {
    margin-bottom: 12.8rem;
  }
  .reverseColumn768 {
    flex-direction: column-reverse;
  }
  .btn {
    font-size: 1.8rem !important;
    margin-bottom: 1.2rem;
  }

  .padding {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  .block {
    min-height: 39.2rem;
    padding-top: 12rem;
    padding-bottom: 15.2rem;
  }
  .column {
    width: 100%;
  }
  .column-text {
    width: 100%;
  }
  .column-image {
    display: none;
  }
}

// scroll animations
.hiddenSA {
  opacity: 0;
  // filter: blur(8px);
  transition: opacity 600ms, filter 600ms, transform 600ms;

  &.leftSA {
    transform: translateX(-10%);
  }
  &.rightSA {
    transform: translateX(10%);
  }
  &.bottomSA {
    transform: translateY(25%);
  }
  &.topSA {
    transform: translateY(-25%);
  }
  &.contactUsSA {
    transform: translateY(20%);
    transition: opacity 1s, filter 1s, transform 1s;
  }
}
.showSA {
  opacity: 1;
  // filter: blur(0);

  &.leftSA,
  &.rightSA,
  &.bottomSA,
  &.topSA,
  &.contactUsSA {
    transform: translate(0);
  }
}

@media (prefers-reduced-motion) {
  .hiddenSA {
    transition: none;
  }
}
