.footer {
  &-right {
    display: flex;
  }
  .language {
    padding-top: 0;
    margin-right: 6.4rem;
    position: relative;
    &-list {
      bottom: 12rem;
    }
    &--mobile {
      left: 3.6rem;
      .language-list {
        bottom: -3rem;
        left: 7.6rem;
      }
    }
  }
  &-content {
    display: flex;
    padding: 6.4rem;
    justify-content: space-between;
    border-radius: 4rem 4rem 0px 0px;
    border: 1px solid var(--bluegray);
    border-bottom: 0px;
    height: 36rem;
  }

  &-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-item {
    font-size: 2.1rem;
    padding-bottom: 1.6rem;
  }
  .transparentButton {
    padding: 0;
    color: var(--white);
    font-weight: 400;
  }
}

@media (max-width: 1024px) {
  .footer {
    &-content {
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3.2rem;
      padding: 6.4rem 3.2rem;
    }
    &-right {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &-item {
      padding-bottom: 0.6rem;
    }
  }
}
