.projects {
  text-align: center;

  &-content {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
    border-radius: 4rem;
    background: radial-gradient(
      110% 108.13% at 50% 10%,
      rgba(15, 15, 15, 0) 46%,
      #005ec9 84.5%,
      #f7f7f8 100%
    ) !important;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    padding: 4rem 0;
  }

  &-item {
    position: relative;
    overflow: hidden;

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2.9rem;
      background: rgba(35, 45, 63, 0.95);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-size: 1.2rem;

      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  h4 {
    // color: var(--gray);
    // text-decoration: underline;
    &:hover {
      border-bottom-color: #c99a00;
    }
  }

  img {
    max-width: 100%;
    border-radius: 3.2rem;
    overflow: hidden;
  }
}


@media (max-width: 768px) {
  .projects {
    &-content {
      padding-bottom: 6.4rem;
    }
  }
}
