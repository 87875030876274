.success {
  text-align: center;
  padding: 12.8rem 25.6rem;
}
.team {
  text-align: center;
  h2 {
    padding-top: 14.4rem;
    padding-bottom: 4.8rem;
  }
  &-info {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 2.4rem;
    }
    &-item {
      display: flex;
      flex-direction: column;
      width: calc(50% - 1.2rem);
      border: 1px solid var(--bluegray);
      padding: 6.4rem;
      border-radius: 40px;
    }
  }
}
.whyUs {
  margin-top: 26.7rem;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: auto;
    padding: 12.8rem 1.6rem;
    gap: 4rem;
  }
  &-list {
    display: flex;
    // flex-wrap: wrap;
  }
  &-item {
    color: var(--gray);
    padding: 0 1.6rem;
    border-right: 2px solid var(--gray);
    &:last-child {
      border-right: none;
    }
  }
}

@media (max-width: 1650px) {
  .whyUs {
    text-align: center;
    &-content {
      padding-top: 9.6rem;
      padding-bottom: 9.6rem;
    }
    &-list {
      flex-direction: column;
      gap: 2.4rem;
    }
    &-item {
      border: none;
    }
  }
}
@media (max-width: 1366px) {
  .success {
    padding: 9.6rem 3.2rem;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .team {
    h2 {
      padding-top: 9.6rem;
      padding-bottom: 4.8rem;
    }
    &-info {
      &-item {
        width: 100%;
        padding: 4.8rem 3.2rem;
      }
    }
  }
}
